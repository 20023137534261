.EventCard {
    /* height: 230px; */
    height: 300px;
    /* width: 200px; */
    width: 350px;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;

    text-decoration: none;

    color: black;

    margin-bottom: 30px;
}
.EventCard .imgs{
    height: 40%;
    width: 100%;
}

.EventCard .imgs {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.1); */

    overflow: hidden;

    z-index: 1;
}

.EventCard .imgs.falsofondo {
    width: 100%;
    height: 100%;

    top: 0%;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.EventCard .imgs img {
    width: auto;
    height: 100%;
}

.EventCard .imgs img.fondo {
    width: 100%;
    height: 100%;

    position: relative;
    top: -100%;

    z-index: -1;
}

.EventCard .texto {
    padding: 20px;
    padding-top: 0px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.EventCard .texto .fecha{
    color: #7A26C1;

    font-weight: bold;
}

.EventCard h3{
    margin: 0;

    color: black;

    text-decoration: none;
    text-align: left;
}
.EventCard button.descargar{
   margin-bottom: 10px;
}