.Entradas {
    height: 100px;
    border-radius: 50px;

    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* background: rgba(255, 255, 255, 0.2); */
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.entradas .formulario_entradas{
    /* height: 200px; */
    width: 100%;

    margin-top: 0px;
    margin-bottom: 30px;
    border-radius: 50px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* background-color: red; */
}

.entradas .formulario_entradas .cantidad_precio, .entradas .formulario_entradas .fechas{
    display: flex;
    justify-content: space-between;
}

.entradas button {
    height: 100%;
    width: 100%;

    /* margin-top: 50px; */
    border-radius: 20px;

    background-color: #7A26C1;
    color: white;
}
.entradas button:hover {
    background-color: #ad50fa;
}
.entradas .nombre h3.nomargin {
    margin-top: 0;
}
