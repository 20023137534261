#PerfilUsuario .header {
    margin-top: 50px;

    background-color: #7A26C1;
    width: 100%;
    height: 20vh;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
}

#PerfilUsuario .header h1 {
    width: 80%;
}
#PerfilUsuario .header button {
   background-color: white;
}

#PerfilUsuario .perfil_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#PerfilUsuario .perfil_section h2{
    width: 80%;
}

#PerfilUsuario .eventos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 80%;
}

.eventos span{
    border-radius: 16px;
    margin-bottom: 30px;
}




#PerfilUsuario .actualizarContainer{
    width: 100%;

    display: flex;
    justify-content: center;
}
#PerfilUsuario .actualizar{
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
}
#PerfilUsuario .actualizar h2{
   width: 100%;

   text-align: left;
}
#PerfilUsuario .actualizar .fila{
   width: 100%;

   margin-bottom: 20px;

   text-align: left;

   display: flex;
   align-items: center;
}
#PerfilUsuario .actualizar .fila span{
    display: block;
   width: 40%;
}

@media (max-width: 500px) {
    #PerfilUsuario .header h1 {
        text-align: center;

        margin-bottom: 10px;
        margin-top: 0px;
    }

    #PerfilUsuario .eventos {
        justify-content: center;
    }

    #PerfilUsuario .header {
        flex-direction: column;
    }
}