#propvalor{
    height: 100vh;
    
    padding-left: 100px;
    padding-right: 100px;

    color: black;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#propvalor .izquierda, #propvalor .derecha{
    width: 30%;
    padding-top: 100px;
}
#propvalor .izquierda{
    width: 40%;
}
#propvalor .izquierda{
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
}

#propvalor .izquierda .texto{
  width: 400px;
  /* background-color: red; */
}
#propvalor .izquierda .texto h2{
   margin-left: 10px;
   margin-bottom: 5px;

   font-size: 2rem;
}

#propvalor .linea{
    height: 2px;
    width: 61%;
    background-color: #7A26C1;
}



#propvalor .derecha{
    width: 50%;
    padding-top: 200px;
 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
#propvalor .derecha .card{
    display: flex;
}
#propvalor .derecha .card .img{
    display: flex;
    justify-content: center;
    align-items: center;
}
#propvalor .derecha .card .img img{
    height: 80px;

    margin-top: 30px;
}
#propvalor .derecha .card .texto{
    margin-left: 20px;
}
#propvalor .derecha .card .texto h4{
    font-size: 1.5rem;
    margin-bottom: 0px;
}
#propvalor .derecha .card .texto p{
    font-size: 0.8rem
}
#propvalor .derecha .card .texto h4 .morado{
    color: #7A26C1;
}
#propvalor .derecha .card .texto h4 .azul{
    color: #6936F5;
}
#propvalor .derecha .card .texto h4 .celeste{
    color: #1BA4E5;
}

#propvalor .fondo{
    position: absolute;

    margin-left: -100px;
    margin-top: 300px;

    height: 800px;

    z-index: -1;
}

@media (max-width: 500px) {
    #propvalor{
        height: fit-content;

        padding-left: 50px;
        padding-right: 50px;

        overflow: hidden;
        flex-direction: column;
    }
    #propvalor .fondo{
        /* position: relative; */

        margin-left: 0;
        margin-top: 300px;

        /* top: 200px; */
        left: -50px;

        height: 600px;
        width: 100vw;
    }
    #propvalor .izquierda, #propvalor .derecha {
        width: 100%;
        padding-top: 100px;
    }
    #propvalor .derecha{
        padding-top: 0px;
    }
}
