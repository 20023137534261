.Eventos {
    padding-top: 100px;
}
.Eventos #EventosHeader {
    height: 400px;
    margin-bottom: 100px;

    background-color: red;
}

.Eventos .title {
    padding-left: 50px;
    margin-bottom: 50px;

    font-size: 1.7rem;
}
.Eventos .title svg {
    height: 60px;
}


.Eventos .eventSection {
    width: 100%;

    margin-bottom: 100px;

    display: flex;
    flex-direction: column;
}

.Eventos .eventSection .CardsContainer{
    padding-left: 50px;
    padding-right: 50px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
}