.FormSection.infoextra .campo{
    width: 100%;

    margin-top: 30px;
}

.FormSection.infoextra .campos .bottonAgregar{

    margin-top: 30px;
}

.FormSection.infoextra .campos .formulario_entradas.campo .DeletButton {

    top: -20px;
}

.FormSection.infoextra .campo .data{
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.FormSection.infoextra .campo .items{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.FormSection.infoextra .campo .items .itemsContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.FormSection.infoextra .campo .items .itemsContainer span{
    background-color: #9C27B0;
    color: white;

    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    margin-top: 5px;

    border-radius: 50px;
}

.FormSection.infoextra .campo .TipoCampo{
    min-width: 160px;
}