.Singin_page{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.Singin_card {
    width: 40%;
    height: 80%;
    /* padding-bottom: 50px;
    padding-top: 50px; */

    background: #FFFFFF;
    border-radius: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.Singin_card h1 {
    color: #7A26C1;
    margin-bottom: 0;

    font-size: 3rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 500px) {
    .Singin_card{
        width: 90%;
    }
}

@media (max-width: 1400px) {
    .Singin_card {
        height: 80%;
        margin-top: 50px;
    }
    .Singin_card h1 {
        margin-top: 20px;
        font-size: 2rem;
    }
}
