#canjearcodigos{
    padding-top: 100px;

    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#canjearcodigos .title{
    margin-top: 50px;
    height: fit-content;
    width: 100%;

    display: flex;
    flex-direction: column;
}

#canjearcodigos .title h1{
    color: #9c27b0;

    font-size: 3rem;

    margin-bottom: 30px;
}

#canjearcodigos .title .MuiSkeleton-root{
    margin-bottom: 30px;
}

#canjearcodigos .title span{
    text-align: center;
}

#canjearcodigos .canjear{
    margin-top: 100px;

    display: flex;
    align-items: center;
    justify-content: space-around;
}
#canjearcodigos .canjear h2{
    width: 50%;
}

#canjearcodigos .canjear button{
    width: 20%;
    height: 50px;
}


#canjearcodigos .backdrop{
    height: fit-content;
    width: 50%;

    margin-top: 50px;
    padding-bottom: 30px;

    background-color: rgba(255, 255, 255, 1);
    color: black;
    border-radius: 16px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

#canjearcodigos .cerrar_container{
    width: 100%;

    margin-top: 10px;
    padding-right: 50px;

    display: flex;
    flex-direction: row-reverse;
}
#canjearcodigos .backdrop .cerrar_container .cerrar{
    height: 50px;
    width: fit-content;

    background-color: transparent;
    color: rgb(95, 95, 95);
    border: none;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}
#canjearcodigos .backdrop .cerrar_container .cerrar svg{
    height: 40px;
    width: 40px;
}
#canjearcodigos .backdrop h1{
    text-align: center;

    margin-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
}

#canjearcodigos .backdrop .CustomFomrElement{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}


#canjearcodigos button.pagar {
    font-size: 2rem;
    height: 50px;
    margin-top: 30px;
    width: 90%;
}

@media (max-width: 500px) {
    #canjearcodigos .title h1{
        text-align: center;
    }

    #canjearcodigos .title span{
        padding-left: 20px;
        padding-right: 20px;
    }
    #canjearcodigos .canjear {
        margin-top: 30px;
        flex-direction: column;
    }
    #canjearcodigos .canjear h2{
        width: 80%;
        text-align: center;
    }
    #canjearcodigos .canjear button{
        width: 95%;

        margin-top: 70px;
    }
    #canjearcodigos .backdrop{
        height: 80vh;
        width: 90%;

        margin-top: 0px;

        overflow: scroll;
    }
    #canjearcodigos .backdrop h1{
        font-size: 1.5rem;

        margin: 0px;
    }
    #canjearcodigos .backdrop input{
        font-size: 0.75rem;
    }
    #canjearcodigos .backdrop label{
        font-size: 0.75rem;
    }
}