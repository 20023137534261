#Cronograma {
    width: 100%;
}

#Cronograma .diaContainer {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    overflow-x: scroll;

}

#Cronograma .diaContainer .agregar_dia {
    width: 250px;
    margin: 0;
}

#Cronograma .diaContainer .agregar_dia button {
    width: 250px;
    margin: 0;
}

#Cronograma .diaContainer .dia {
    width: 250px;
    height: fit-content;

    margin-right: 20px;

}

#Cronograma .diaContainer .dia button {
    width: 250px;
    margin: 0;

    margin-bottom: 30px;
}

#Cronograma .diaContainer .dia .title {
    height: fit-content;
    width: 200px;

    padding: 25px;
    /* padding-top: 30px;
    padding-bottom: 30px; */

    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;


    border-width: 5px;
    border-bottom-style: solid;


    /* From https://css.glass */
    /* background: rgba(255, 255, 255, 0.2); */
    background: rgba(130, 33, 165, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

#Cronograma .diaContainer .dia .title h3 {
    color: #7A26C1;

    margin: 0;
}

#Cronograma .diaContainer .dia .actividad {
    margin-bottom: 20px;
    padding: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;


    border-width: 5px;
    border-bottom-style: solid;


    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

#Cronograma .diaContainer .dia .actividad .CheckBoxContainer {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
#Cronograma .diaContainer .dia .actividad h4 {
    margin: 0;
}

#Cronograma form button {
    width: 100%;

    margin: 0;
    margin-top: 50px;
}