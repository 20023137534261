#eventPage .header {
    background-color: #7A26C1;
    width: 100%;
    height: 60vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

#eventPage .header .imgs {
    width: 80%;
    height: 60vh;

    position: relative;
    top: 20%;

    border-radius: 50px;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.1); */

    overflow: hidden;

    z-index: 1;
}

#eventPage .header .imgs.falsofondo {
    width: 100%;
    height: 100%;

    top: 0%;

    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;
    flex-direction: column;
}

#eventPage .header .imgs img {
    /* width: auto; */
    width: 100%;
    /* height: 100%; */
}

#eventPage .header .imgs img.fondo {
    width: 100%;
    height: 100%;

    position: relative;
    top: -100%;

    z-index: -1;
}

/* BASICINFO */
.basicInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.basicInfo div {
    width: 80%;
}

.fecha_comprar {
    margin-top: 7%;
    display: flex;
    justify-content: space-between;
}

.fecha_comprar span {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #7A26C1;
    font-size: 1.5rem;
}

.basicInfo .titulo h1 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 3rem;
}

.basicInfo .cuando_donde {
    display: flex;
}

.basicInfo .cuando_donde .donde,
.basicInfo .cuando_donde .cuando {
    margin-top: 20px;
}

.basicInfo .cuando_donde .cuando {
    width: 50%;
}

.basicInfo .cuando_donde .donde {
    width: 40%;
}

.basicInfo .cuando_donde .separador {
    height: 150px;
    width: 4px;
    border-radius: 50px;
    background-color: rgb(56, 56, 56);
}

.basicInfo .cuando_donde .donde {
    margin-left: 80px;
}


.title {
    display: flex;
    align-items: center;

    height: 60px;
}

.title svg {
    height: 40px;
    width: auto;

    margin-right: 20px;

    color: #7A26C1;
}

.basicInfo .cuando_donde h4 {
    margin: 0;
}

.descripcion {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 100px;
}

.descripcion .TarjetaDescripcion {
    /* From https://css.glass */
    /* background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3); */

    width: 100%;

    padding-left: 30px;
    padding-right: 30px;

    display: flex;
    justify-content: space-between;
}

.descripcion.entrada .TarjetaDescripcion{
    flex-direction: column;
}
.descripcion.entrada .TarjetaDescripcion .incluye{
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 90%;
    padding-left: 20px;
    padding-right: 30px;
}
.descripcion.entrada .TarjetaDescripcion .incluye ul{
    padding: 0;
    color: #9C27B0;

    font-size: 1.1rem;
}
.descripcion.entrada .TarjetaDescripcion .incluye ul li span{
    color: black;
}
.descripcion.descuentos .TarjetaDescripcion{
    flex-direction: row;
    margin-bottom: 30px;
}

.descripcion .TarjetaDescripcion .texto{
    width: 70%;
}
.descripcion .TarjetaDescripcion .descuento{
    width: 30%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem;
    color: #9C27B0;
}

.descripcion div {
    width: 80%;
}

#eventPage .organizador {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#eventPage .organizador .data,
#eventPage .organizador .titulo {
    width: 80%;
}

#eventPage .organizador .data {
    display: flex;
}

#eventPage .organizador .data h3 {
    width: 20%;
    height: 100%;
}

#eventPage .organizador .data .img {
    max-height: 200px;
    max-width: 80%;
    /* height: 200px; */
    /* width: 200px; */
}

#eventPage .macrobotoncomprar {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#eventPage .macrobotoncomprar button {
    width: 80%;
    height: 70px;

    border-radius: 50px;

    font-size: 2rem;

    margin-top: 30px;
}

#eventPage .gerentes {
    width: 100%;

    margin-top: 30px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#eventPage .gerentes .container {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

#eventPage .gerentes .ButtonContainer {
    width: 20%;
}

#eventPage .gerentes .ButtonContainer button {
    width: 100%;
    height: 80%;

    margin-left: 0;
    margin-right: 0;
}

@media (max-width: 500px) {
    .fecha_comprar {
        flex-direction: column-reverse;
        align-items: center;
    }

    .fecha_comprar button {
        width: 100%;
        height: 50px;
    }

    .basicInfo .titulo h1 {
        font-size: 2rem;
        text-align: center;
    }

    .fecha_comprar span {
        text-align: center;
    }

    #eventPage .header {
        height: 50vh;
    }

    #eventPage .header .imgs.falsofondo {
        justify-content: center;
    }

    #eventPage .header .imgs img {
        width: 100%;
        height: auto;
    }

    #eventPage .header .imgs {
        width: 80%;
        height: 30vh;

        top: 5%;
    }

    .separador {
        display: none;
    }

    #eventPage .macrobotoncomprar button {
        width: 80%;
        height: 70px;

        border-radius: 50px;

        font-size: 1.5rem;
    }

    #eventPage .macrobotoncomprar button:disabled {
        font-size: 1rem;
    }

    #eventPage .organizador .data {
        width: 100%;
        flex-direction: column;
        align-items: center
    }

    #eventPage .organizador .img {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #eventPage .gerentes .container {
        flex-direction: column;
        align-items: center;
    }

    #eventPage .gerentes .ButtonContainer {
        width: 100%;
    }
}