#Admin .header {
    margin-top: 50px;

    background-color: #7A26C1;
    width: 100%;
    height: 20vh;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
}

#Admin .header h1 {
    width: 80%;
}

#Admin .header .container {
    width: 80%;
    height: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#Admin .header .container button {
    background-color: white;
}