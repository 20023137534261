.index .bottom .cards_div {
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  width: 100%;
}

.tarjeta_beneficios {
  height: 90px;
  width: 100%;

  border-radius: 50px;
  margin-bottom: 30px;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  overflow: hidden;

  display: flex;
}

.tarjeta_beneficios .icon_conteiner {
  height: 100%;
  width: 30%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tarjeta_beneficios .text_container {
  width: 70%;
  display: flex;
  align-items: center;
}

.tarjeta_beneficios svg {
  height: 60px;
  width: auto;
}

.tarjeta_beneficios p {
  display: none;
}

@media (min-width: 1400px) {
  .tarjeta_beneficios {
    width: 30%;
    /* height: 150px; */
  }
  .tarjeta_beneficios .text_container  h2{
    font-size: 1.5rem;
  }
  .tarjeta_beneficios p {
    display: block;
    font-size: 0.9vw;
  }
}

@media (min-width: 720px) {
  .index .bottom .cards_div {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .tarjeta_beneficios {
    width: 45%;
    height: 200px;
  }

  .tarjeta_beneficios .text_container {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 20px;
  }
  .tarjeta_beneficios .text_container  h2{
    font-size: 1.3rem;
  }

  .tarjeta_beneficios p {
    display: block;
    /* font-size: 1.1vw; */
  }
}