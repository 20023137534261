.DescuentosAPIs{
    /* height: 65px; */
    height: fit-content;
}

.DescuentosAPIs.certificado {
    flex-direction: column;
}
.DescuentosAPIs.certificado {
    flex-direction: column;
}
.certificado .container{
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.certificado small{
    width: 100%;
    margin-bottom: 20px;
}
.certificado input{
    width: 40%;
}