.insignias {
    height: 200px;
}

.insignias .swiper {
    width: 80%;
    height: 200px;
}

.insignias .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */

    /* Center slide text vertically */
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.insignias .swiper-slide img {
    display: block;
    height: 100%;
    width: auto;
    object-fit: cover;
}


@media (max-width: 500px) {
    .insignias .swiper {
        width: 100%;
        height: 300px;
    }
    .insignias .swiper-slide img {
        height: 70%;
    }
}