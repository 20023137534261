.container{
    width: 100%;

    display: flex;
    justify-content: center;
}
.CrearEventoNavBar{
    width: 85%;

    margin-top: 100px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: relative;
}

.CrearEventoNavBar .link{
    height: 90px;
    max-width: 60px;

    z-index: 2;

    text-align: center;
    color: #313131;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.CrearEventoNavBar .link .circle{
    width: 50px;
    height: 50px;

    border-radius: 50px;
    background-color: #9C27B0;
    background-color: #313131;
    color: white;
    font-size: 1.7rem;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.25s;
}
.CrearEventoNavBar .link .circle.activated{
    background-color: #838383;
}
.CrearEventoNavBar .link .circle.activated.actual{
    background-color: #9C27B0;
}
.CrearEventoNavBar .link .circle.Disabled{
    pointer-events: none;
}

.CrearEventoNavBar .link.active:hover .circle{
    background-color: #9C27B0;
    cursor: pointer;
}

.CrearEventoNavBar .bar{
    position: absolute;
    top: 20px;
    left: 10px;

    z-index: 1;

    width: 99%;
    height: 10px;

    background-color: #313131;
}

@media (max-width: 1400px) {
    .CrearEventoNavBar .bar{
        width: 95%;
    }
}

@media (max-width: 500px) {
    .CrearEventoNavBar .bar{
        width: 90%;
    }
}