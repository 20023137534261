#header {
    padding-top: 130px;
    padding-left: 5%;
    padding-right: 5%;

    background-image: url("../imgs/fondo\ header\ landing.svg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: auto;

    padding-bottom: 50px;
}

.index .top {
    display: flex;
    flex-direction: column;
}

.index .top .izquierda {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.index .top .derecha {
    display: none;
}

.index .top .izquierda img {
    width: 50vw;
    height: auto;
}

.index .top .izquierda p {
    width: 70vw;
    height: auto;

    font-size: 1.2rem;
    text-align: center;
}

.index .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media (min-width: 720px) {
    #header {
        padding-bottom: 100px;
    }
    
    .index .top {
        display: flex;
        flex-direction: row;
        justify-content: center;

        padding-left: 10%;
        padding-right: 10%;
    }

    .index .top .izquierda {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        width: 50%;
    }

    .index .top .derecha {
        display: block;
        width: 50%;
    }

    .index .top .izquierda img {
        width: 50%;
        height: auto;
    }

    .index .top .izquierda p {
        width: 80%;
        height: auto;

        font-size: 1.2rem;
        text-align: left;
    }

    .index .top .derecha img {
        width: 120%;
        height: auto;

        position: relative;
        top: -130px;
        left: -50px;
    }
}

@media (min-width: 1450px) {
    #header {
        background-size: cover;
        padding-top: 300px;
        padding-bottom: 150px;
    }

    .index .top .derecha img {
        top: -300px;
        left: -50px;
    }
}