.Acreditar{
    width: 80%;

    margin-top: 50px;

    display: flex;
    flex-direction: column;
}
.Acreditar form{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.Acreditar form button{
    width: 100%;

    margin-top: 20px;
}
.Acreditar .tipo_entrada{
    width: 100%;

    text-align: center;

    margin-top: 00px;
    margin-bottom: 0px;
}