.EliminarCuenta {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.EliminarCuenta .Login_card{
    width: 70%;
    height: 30vh;

    margin-top: 10%;
}