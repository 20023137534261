.cronograma {
    width: 100%;
}

.cronograma .actividades{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cronograma .actividades .actividad{
    height: 80px;
    width: 80%;

    background-color: hsla(273, 67%, 45%, 0.3);

    display: flex;
    flex-direction: row;
}

.cronograma .actividades .actividad .banda_oscura{
    height: 100%;
    width: 20px;

    background-color: #7a26c0;
}

.cronograma .actividades .actividad .info1, .cronograma .actividades .actividad .disertante{
    height: 100%;
    width: 20%;

    background-color: red;
}

.cronograma .actividades .actividad .descripcion{
    height: 100%;
    width: 60%;

    background-color: blue;
}