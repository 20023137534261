#eventPage .gerentes .iscriptos{
    width: 80%;

    margin-top: 30px;
}
#eventPage .gerentes .cantidades div{
   display: flex;
   flex-direction: column;
}
#eventPage .gerentes .cantidades span{
   color: #7A26C1;
}

#eventPage .gerentes .sorteos{
   width: 100%;
   display: flex;
    flex-direction: column;
    align-items: center;
}
#eventPage .gerentes .sorteos .botones{
   width: 80%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-evenly;
}

#eventPage .gerentes .sorteos .ganador{
   width: 80%;

   margin-top: 50px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

#eventPage .gerentes .tipoEntrada{
   width: 100%;

   margin-top: 50px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

#eventPage .gerentes .tipoEntrada .botones{
   width: 100%;

   display: flex;
   justify-content: center;
}

#eventPage .gerentes .tipoEntrada .numeros{
   width: 100%;

   display: flex;
   flex-direction: column;
   align-items: center;
}

@media (max-width: 500px) {
   #eventPage .gerentes .sorteos .botones{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
   }
}