.index {
  /* height: 90vh; */
  color: white;
  margin-bottom: 200px;
}

.index button{
  margin: 0;
}

h1 {
  font-size: 2rem;
  margin-top: 60px;
  margin-bottom: 60px;
}