.CrearEvento {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CrearEvento .FormSection {
    width: 80%;

    margin-top: 50px;
    /* margin-bottom: 25px; */
    padding: 50px;

    border-radius: 25px;
    background-color: white;
}

.CrearEvento .FormSection h2 {
    margin-top: 0;

    color: #9C27B0;
    font-size: 2.5rem;
}

.CrearEvento button.siguiente {
    width: 80%;

    margin: 0px;
    margin-top: 100px;
}

.DeletButton {
    height: 50px !important;
    width: 100% !important;

    text-align: right;

    background-color: transparent !important;
    border: none;

    color: black !important;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    cursor: pointer;

    position: relative;

    /* top: 35px; */
}

.DeletButton svg {
    color: red;

    height: 50px;
    width: 50px;
}