#EditarEvento {
    width: 100%;

    margin-top: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
#EditarEvento .container{
    justify-content: center !important;
}
#EditarEvento .tabpanel{
    width: 80%;
}

/* #EditarEvento div{
    width: 80%;
}
#EditarEvento div div{
    width: 100%;
} */

