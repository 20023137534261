#CrearEntradas {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#CrearEntradas .EntradasPromocionales {
    width: 100%;

    display: flex;
    flex-direction: column;
}

#CrearEntradas .EntradasPromocionales .codigos {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#CrearEntradas .EntradasPromocionales .title {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#CrearEntradas .EntradasPromocionales .codigos .codigocontainer{
    width: 100%;

    max-height: 300px;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start; */
}
#CrearEntradas .EntradasPromocionales .codigo {
    width: 80%;

    margin-bottom: 20px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
#CrearEntradas .EntradasPromocionales .codigo.true {
    background: rgba(94, 255, 0, 0.2);
}
#CrearEntradas .EntradasPromocionales .codigo.false {
    background: rgba(255, 0, 0, 0.2);
}
#CrearEntradas .EntradasPromocionales .codigo .name {
    margin-top: 10px;
}

#CrearEntradas form.promocionales {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#CrearEntradas form.promocionales div {
    margin: 0;
    margin-bottom: 10px;
}