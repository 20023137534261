.entradacontainer{
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.entradacontainer .entrada {
    max-height: 900px;
    max-width: 600px;

    width: 99vw;
    height: 90vh;

    background-color: white;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;

    text-decoration: none;

    color: black;

    margin-bottom: 30px;
}

.section{
    width: 100%;
    height: 30%;

    background-color: #dec4f3;

    border-radius: 16px;
}
.section.info{
    display: flex;
    /* height: 35%; */
}
.section.info.Principal{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.section.info .text{
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.section.info .text h3{
    margin: 0;
}
.section.info .text span{
    margin: 0;
}
.section.info .text a{
    height: 40px;
    width: 200px;

    border-radius: 16px;
    text-decoration: none;

    color: white;
    background-color: #7A26C1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.section.info .qr{
    height: 200px;
    width: 200px;

    background-color: black;
}


.entradacontainer .entrada .imgs {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    overflow: hidden;

    z-index: 1;
}

.entradacontainer .entrada .imgs.falsofondo {
    width: 100%;
    height: 100%;

    top: 0%;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.entradacontainer .entrada .imgs img {
    width: auto;
    height: 100%;
}

.entradacontainer .entrada .imgs img.fondo {
    width: 100%;
    height: 100%;

    position: relative;
    top: -100%;

    z-index: -1;
}

.section.info.Secundaria {
    height: 200px;
    width: 100%;
}
.section.info.footer {
    background-color: #7A26C1;

    height: 100px;
    width: 100%;
}
.section.info.footer a{
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.section.info.footer img{
    height: 80%;
    width: auto;
}