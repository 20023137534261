input[type="file"] {
    display: none;
}

.FormSection.media img{
    height: 100px;
    width: auto;

    margin-top: 20px;
}

.FormSection.media .custom-file-upload {
    height: 200px;
    width: 100%;
    margin-top: 50px;

    border: 1px solid #ccc;
    border-radius: 16px;
    cursor: pointer;

    transition: all 0.5s;

    display: flex;
    align-items: center;
    justify-content: center;
}
.FormSection.media .custom-file-upload:hover{
    border: 1px solid #9C27B0;
}

.FormSection.media .custom-file-upload svg{
    height: 50px;
    width: auto;
    color: #9C27B0;
}