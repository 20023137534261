#ComprarEntradas {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 400px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#ComprarEntradas .header {
    width: 100%;
    height: 20vh;
    /* margin-top: 50px; */

    background-color: #7A26C1;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}
#ComprarEntradas .header h1{
    font-size: 3rem;
    text-align: center;
}
#ComprarEntradas .content {
    width: 80%;

    margin-top: 50px;
}
#ComprarEntradas .content .entradas{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#ComprarEntradas .content .entradas button.pagar{
   width: 90%;
   height: 50px;

   margin-top: 50px;

   font-size: 2rem;

   position: fixed;
   bottom: 50px;
   /* left: 0; */

   z-index: 1;
}

#ComprarEntradas .entrada, #ComprarEntradas .cupones, #ComprarEntradas .total, .DescuentosAPIs {
    width: 80%;

    margin-top: 30px;
    padding-left: 40px;
    padding-right: 40px;

    /* From https://css.glass */
    background-color: white;
    /* background: rgba(255, 255, 255, 0.2); */
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    display: flex;
    align-items: center;

    z-index: 1;
}

#ComprarEntradas .total{
    position: fixed;
    bottom: 120px;
}
#ComprarEntradas .entrada .data{
    width: 100%;
}
#ComprarEntradas .entrada .data{
    margin-bottom: 10px;
}

#ComprarEntradas .entrada .cantidad{
    display: flex;
    align-items: center;
    justify-content: center;
}
#ComprarEntradas .entrada .cantidad div{
    width: 100%;
}
#ComprarEntradas .entrada .cantidad button{
    border: none;
    background-color: transparent;
    color: black;

    width: fit-content;
}
#ComprarEntradas .entrada .cantidad svg{
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

#ComprarEntradas .cupones{
    font-size: 1rem;

    padding-top: 10px;
    padding-bottom: 10px;

    justify-content: space-between;
}
#ComprarEntradas .cupones .input_cupon{
    width: 40%;
}
#ComprarEntradas .cupones .aplicar{
    width: 200px;
}

.MuiBackdrop-root {
    z-index: 99999!important;
}

#ComprarEntradas .backdrop{
    height: fit-content;
    /* max-height: 70vh; */
    width: 50%;

    padding-bottom: 50px;

    background-color: rgba(255, 255, 255, 1);
    color: black;
    border-radius: 16px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

#ComprarEntradas .cerrar_container{
    width: 100%;

    margin-top: 10px;
    padding-right: 50px;

    display: flex;
    flex-direction: row-reverse;
}
#ComprarEntradas .backdrop .cerrar_container .cerrar{
    height: 50px;
    width: fit-content;

    background-color: transparent;
    color: rgb(95, 95, 95);
    border: none;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}
#ComprarEntradas .backdrop .cerrar_container .cerrar svg{
    height: 40px;
    width: 40px;
}
#ComprarEntradas .backdrop h1{
    width: 80%;

    text-align: center;
    font-size: 1.5rem;

    margin-top: 0px;
    margin-bottom: 30px;
}

#ComprarEntradas .backdrop .CustomFomrElement{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.swal2-container, .swal2-container.swal2-center>.swal2-popup{
    z-index: 99999 !important;
}



@media (max-width: 500px) {
    #ComprarEntradas .header h1{
        font-size: 2rem;
        text-align: center;
    }

    #ComprarEntradas .entrada .data{
        text-align: center;
    }
    
    #ComprarEntradas .entrada {
        flex-direction: column;
        padding-bottom: 40px;
    }

    #ComprarEntradas .backdrop{
        width: 80%;
    }
    
}

@media (max-width: 400px) {

    #ComprarEntradas .backdrop{
        width: 90%;
        padding-bottom: 20px;
    }
    #ComprarEntradas .backdrop h1{
        margin: 0;
        font-size: 1rem;
    }
    #ComprarEntradas .backdrop .cerrar_container button{
        margin-top: 0px !important;
    }
    #ComprarEntradas .backdrop button{
        margin-top: 20px !important;
        font-size: 1rem !important;
    }
}
@media (max-height: 400px) {

    .MuiBackdrop-root {
        overflow: scroll;
        height: 100vh;
    }
}