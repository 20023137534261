.MatchAle{
    width: 100%;
    display: flex;
    justify-content: center;
}
.MatchAle .Login_card{
    width: 60%;
    height: 500px;
    padding-left: 100px;
    padding-right: 100px;
}