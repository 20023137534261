.asistencia {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.asistencia .formcontrol{
    margin-top: 50px;
}

.asistencia .asistenciaContainer {
    width: 80%;
}

.asistencia .asistenciaContainer ul {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    padding: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
}


.asistencia .asistenciaContainer li {
    list-style: none;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 10px;

    height: 40px;

    /* From https://css.glass */
    /* background: rgba(255, 255, 255, 0.2); */
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    overflow: hidden;
}

.asistencia .asistenciaContainer li.dia{
    font-size: 1.5rem;

    font-weight: bold;
}

.asistencia .asistenciaContainer li.actividad button{
    height: 100%;
    width: 100%;

    background-color: transparent;
    border: none;
}

.asistencia .asistenciaContainer li.actividad input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
}
.asistencia .asistenciaContainer li.actividad label{
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.25s;
}


.asistencia .asistenciaContainer li.actividad input:checked ~ label{
    color: white;
    background-color: #9C27B0;
}

.asistencia .boton_submit{
    visibility: hidden;
}
