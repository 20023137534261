.forgot_page{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.forgot_page .Login_card {
    width: 40%;
    height: 80%;
    /* padding-bottom: 50px;
    padding-top: 50px; */

    background: #FFFFFF;
    border-radius: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.forgot_page .Login_card img {
    margin-top: 20px;
    height: 130px;
    height: auto;
}
.forgot_page .Login_card h1 {
    color: #7A26C1;
    margin-bottom: 0;
}
.forgot_page .Login_card h2 {
    color: #00000080;
    font-size: 1rem;
}
.forgot_page .Login_card small {
    width: 70%;
    text-align: center;
}

@media (max-width: 1400px) {
    .forgot_page .Login_card {
        margin-top: 50px;
    }
    .forgot_page .Login_card img {
        margin-top: 20px;
        height: 100px;
        width: auto;
    }
    .forgot_page .Login_card h1 {
        margin-top: 20px;
        font-size: 1.5rem;
    }
    .forgot_page .Login_card p {
        margin: 0px;
        font-size: 0.7rem;
    }
}


@media (max-width: 720px) {
    .forgot_page .Login_card{
        width: 90%;
    }
}