.Navbar {
    position: fixed;
    top: 0;
    z-index: 50000;

    width: 100vw;
    height: 60px;

    /* color: white; */
    color: #7A26C1;

    /* background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px); */

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

    background-color: #ebe4ff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 20px;
}

.Navbar img{
    height: 90%;
}
.Navbar button{
    background-color: transparent;
    border-style: none;
}
.Navbar button:hover{
    cursor: pointer;
}

.Navbar .menu_container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    height: 100%;
    /* width: 50%; */
    padding-right: 70px;
}

.Navbar .menu_container button {
    background: none;
    border: none;

    cursor: pointer;
}

.Navbar .menu_container a{
    height: 100%;
    margin-right: 50px;

    display: flex;
    align-items: center;


    color: #7A26C1;
    font-size: 1.3rem;
    text-decoration: none;

    transition: color 0.5s;
}

.menu_link{
    /* font-size: 1.3rem; */
    text-decoration: none;
    color: black;
}

.Navbar .menu_container a:hover{
    color: deeppink;

    /* background-color: black; */
    /* transition: color s; */
}

.Navbar a img{
    height: 50px;
    width: auto;
}